import { easeCubic } from "d3-ease";
import { F1Year, F1YearlyDriver, F1YearlyConstructor, F1Driver } from "./firebaseData";

export class Rect {
    x0: number;
    y0: number;
    x1: number;
    y1: number;

    constructor(public x: number, public y: number, public width: number, public height: number) {
        this.x0 = x;
        this.x1 = x + width;
        this.y0 = y;
        this.y1 = y + height;
    }

    static create(x0: number, y0: number, x1: number, y1: number): Rect {
        return new Rect(x0, y0, x1 - x0, y1 - y0);
    }
}

export class NumberTransition {
    initialized = false;
    current = 0;

    constructor(public before = 0, public after = 0) {
        this.initialized = arguments.length === 2;
        this.current = before;
    }

    setAfter(after: number): void {
        if (!this.initialized) {
            this.current = after;
            this.initialized = true;
        }
        this.before = this.current;
        this.after = after;
    }
}

export class NormalizedTransition {
    startTime = window.performance.now();
    duration = 1000;
    progress = 0;
    easing = easeCubic;

    restart(): void {
        this.startTime = window.performance.now();
    }

    update(): number {
        let diff = window.performance.now() - this.startTime;
        if (diff >= this.duration) {
            this.progress = 1;
        } else {
            let normTime = diff / this.duration;
            this.progress = this.easing(normTime);
        }
        return this.progress;
    }

    transition(transition: NumberTransition) {
        transition.current = this.interpolate(transition.before, transition.after);
    }

    interpolate(before: number, after: number) {
        return this.progress * (after - before) + before;
    }

    isDone(): boolean {
        return this.progress === 1;
    }
}

export interface DriverCtorGroup {
    year: F1Year;
    constructor: F1YearlyConstructor;
    yDriver: F1YearlyDriver;
    driver: F1Driver;
    points: number;
    pos: NumberTransition;
}

export interface CachedImg {
    srcUrl: string;
    imgLoaded: boolean;
    img?: HTMLImageElement;
}
