import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

interface Props {
  open: boolean;
}

export class Navbar extends React.Component<{}, Props> {

  constructor(props: any) {
    super(props);
    this.state = { open: false };

    this.flip = this.flip.bind(this);
    this.close = this.close.bind(this);
  }

  flip() {
    this.setState({ open: !this.state.open })
  }

  close() {
    this.setState({ open: false })
  }

  render() {
    let suffix = this.state.open ? "-open" : "-closed";
    let fullSize = { width: window.innerWidth, height: window.innerHeight };
    return (
      <div style={fullSize}>
        <div className={"nav-glass nav-glass" + suffix} onClick={this.close} style={fullSize} />
        <div className="burger" onClick={this.flip}>
          <div className={"burger-1" + suffix} />
          <div className={"burger-2" + suffix} />
          <div className={"burger-3" + suffix} />
        </div>
        <nav className={"nav" + suffix}>
          <div><Link to="/" onClick={this.close}>Home</Link></div>
          <div><Link to="/f1" onClick={this.close}>FORMULA 1</Link></div>
          <div><Link to="/about" onClick={this.close}>About</Link></div>
          <div><Link to="/privacy" onClick={this.close}>Privacy</Link></div>
        </nav>
      </div>
    );
  }
}

export default Navbar;