import React from 'react';
import './Home.css';
import f1full from '../../images/home/f1full.png';
import f1ctors from '../../images/home/f1ctors.png';
import f1drivers from '../../images/home/f1drivers.png';
import f1ctorsbynametoolbar from '../../images/home/f1ctorsbynametoolbar.png';
import f1ctorsbyname from '../../images/home/f1ctorsbyname.png';

export default class Home extends React.Component {
    render() {
        return (
            <div style={{ width: window.innerWidth, height: window.innerHeight }}>
                <div className="title" style={{ height: 48 }}>
                    <a href="/">Racing Viz</a>
                </div>
                <div style={{ height: window.innerHeight - 48, overflow: 'auto', display: 'flex' }}>
                    <div className="home" style={{ maxWidth: 1024, flex: 1 }}>
                        <div>
                            <h1>Welcome to <span className="racingviz">Racing Viz</span>!</h1>
                            <p>This site is dedicated to creating unique interactive racing visualizations.</p>
                            <h2>Formula 1 - Graph</h2>
                            <p>The Formula 1 graph currently displays the Constructor and Driver teams and standings from 2017 to 2021.</p>
                            <div style={{ textAlign: 'center' }}>
                                <img src={f1full} alt="Formula 1 - Graph" style={{ maxWidth: "90%" }}></img>
                            </div>
                            <h2>Formula 1 - Contructors &amp; Drivers</h2>
                            <p>The graph can be switched between showing Constructors only, Drivers only or both Constructors and Drivers using the buttons in the toolbar.</p>
                            <p>Constructors only:</p>
                            <div style={{ textAlign: 'center' }}>
                                <img src={f1ctors} alt="Formula 1 - Constructors" style={{ width: 600, maxWidth: "80%" }}></img>
                            </div>
                            <p>Drivers only:</p>
                            <div style={{ textAlign: 'center' }}>
                                <img src={f1drivers} alt="Formula 1 - Drivers" style={{ width: 600, maxWidth: "80%" }}></img>
                            </div>
                            <h2>Formula 1 - Links and Sorting</h2>
                            <p>When it comes to the teams, year-to-year related teams are linked together. For instance when teams are sold and/or renamed there will still be a link from the old team to the new team. There has been a bunch of team transitions and name changes over recent years, such as Sauber changing to Alfa Romeo Racing, Scuderia Toro Rosso changing to AlphaTauri, Renault changing to Alphine and Force India changing to Racing Point and then later to Aston Martin.</p>
                            <p>The team acquisition and name changes can be visualized by showing Constructors only, and then sorting by Name instead of Points.</p>
                            <div style={{ textAlign: 'center' }}>
                                <img src={f1ctorsbynametoolbar} alt="Formula 1 - Toolbar - Constructors by Name" style={{ maxWidth: "calc(100% - 80px)", paddingLeft: 40, paddingRight: 40 }}></img>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <img src={f1ctorsbyname} alt="Formula 1 - Constructors by Name" style={{ maxWidth: "85%" }}></img>
                            </div>
                            <p>As of yet, there is only a graph displaying the last few seasons of Formula 1, but the plan is to also expand to other racing championships over time (possibly MotoGP and Formula E).</p>
                            <p>For any feedback, suggestions or visualization requests, please send an email to <a href="mailto:racingvizsite+h@gmail.com">racingvizsite+h@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}