import * as d3 from 'd3';
import React from "react";
import { Grouping, Sort, ToolbarState } from '../../data/toolbarData';
import { TouchData } from '../../data/touchData';

export default class F1Toolbar extends React.Component<{ onState: (state: ToolbarState) => void; }> {

    toolbarState = new ToolbarState();
    toolbarRef!: SVGSVGElement;
    toolbarStartX = 0;
    toolbarTouchX = 0;
    toolbarX = 0;
    toolbarWidth = 800; // Update when toolbar buttons change
    firstRender = true;
    touch = new TouchData();
    lastPaintTime = 0;

    constructor(onState: (state: ToolbarState) => void) {
        super({ onState });
    }

    componentDidMount() {
        this.update();
        this.forceUpdate();
    }

    setShow(show: Grouping): void {
        this.toolbarState.show = show;
    }

    setSort(sort: Sort): void {
        this.toolbarState.sort = sort;
    }

    setLink(link: Grouping): void {
        this.toolbarState.link = link;
    }

    update() {
        let toolbarHeight = 20;

        // Toolbar
        let time = new Date().getMilliseconds();
        var toolbarSelection = d3.select(this.toolbarRef)
            .selectAll<SVGSVGElement, object>(".toolbar")
            .data([time], d => d.toString()); // Todo: How to recreate each time
        toolbarSelection.exit()
            .remove();
        let toolbar = toolbarSelection.enter()
            .append("svg")
            .attr("class", "toolbar")
            .attr("x", 10)
            .attr("y", 10)
            .attr("height", toolbarHeight)
            .attr("font-family", "FOneBold");

        // Toolbar: Groups
        let btnTxtY = 12;
        let btnSelColor = "#fff";
        let btnOffColor = "#444";
        let textGap = 6;
        let xOffset = textGap * 2.5;
        let textNextX = (d: any, i: number, elements: SVGTextElement[] | ArrayLike<SVGTextElement>) => {
            let w = elements[i].getComputedTextLength();
            let x = xOffset;
            xOffset += w + textGap;
            return x;
        };
        let fillColor = (selection: d3.Selection<SVGTextElement, any, any, any>, condition: boolean, color: string) => {
            if (condition) {
                selection.transition().duration(200).attr('fill', color);
            }
        };
        let appendButtonTitle = (text: string, color: string) => {
            toolbar.append("text")
                .attr("font-family", "FOneRegular")
                .attr("font-size", 14)
                .attr("fill", color)
                .text(text + ":")
                .attr("x", textNextX)
                .attr("y", btnTxtY);
        };
        let appendButton = (text: string, cls: string, btnValue: Grouping | Sort, value: Grouping | Sort, setValue: (value: Grouping | Sort) => void, color: string, hoverColor: string, fontSize: number = 16, y: number = btnTxtY) => {
            toolbar.append("text")
                .attr("class", cls)
                .attr("font-size", fontSize)
                .text(text)
                .attr("x", textNextX)
                .attr("y", y)
                .attr("cursor", "pointer")
                .on('mouseover', function () { fillColor(d3.select(this), value !== btnValue, hoverColor) })
                .on('mouseout', function () { fillColor(d3.select(this), true, color) })
                .on("click", d => {
                    setValue(btnValue);
                    this.props.onState(this.toolbarState);
                    this.update();
                    // this.animStart = new Date().getTime();
                    // this.updateGraph();
                    // if (!this.repaintRequested) {
                    //     this.repaintRequested = true;
                    //     requestAnimationFrame(() => this.drawCanvas());
                    // }
                });
            d3.select(this.toolbarRef)
                .selectAll("." + cls)
                .attr("fill", color);
        };

        // Toolbar: Show Buttons
        let showBtnColor = "#FF8700";
        let showHvrColor = "#fd8";
        let showCtorsBtnColor = this.toolbarState.show !== Grouping.Drivers ? btnSelColor : btnOffColor;
        let showAllBtnColor = this.toolbarState.show === Grouping.Both ? btnSelColor : btnOffColor;
        let showDriversBtnColor = this.toolbarState.show !== Grouping.Constructors ? btnSelColor : btnOffColor;
        appendButtonTitle("Show", showBtnColor);
        appendButton("Constructors", "showButtonCtors", Grouping.Constructors, this.toolbarState.show, (value) => this.setShow(value as Grouping), showCtorsBtnColor, showHvrColor);
        appendButton("+", "showButtonAll", Grouping.Both, this.toolbarState.show, (value) => this.setShow(value as Grouping), showAllBtnColor, showHvrColor, 24, 15);
        appendButton("Drivers", "showButtonDrivers", Grouping.Drivers, this.toolbarState.show, (value) => this.setShow(value as Grouping), showDriversBtnColor, showHvrColor);
        xOffset += textGap * 0.5;
        toolbar.append("path")
            .attr("stroke", showBtnColor)
            .attr("fill", "none")
            .attr("stroke-width", textGap)
            .attr("d", d => d3.line()([[textGap, 0], [textGap, toolbarHeight], [xOffset, toolbarHeight], [xOffset, 0]]));

        // Toolbar: Sort
        let sortCtorsBtnColor = "#00D2BE";
        let sortCtorsHvrColor = "#afd";
        xOffset += textGap * 2.5;
        let groupXStart = xOffset;
        xOffset += textGap * 1.5;
        let sortCtorsPointsBtnColor = this.toolbarState.sort === Sort.Points ? btnSelColor : btnOffColor;
        let sortCtorsNameBtnColor = this.toolbarState.sort === Sort.Name ? btnSelColor : btnOffColor;
        appendButtonTitle("Sort", sortCtorsBtnColor);
        appendButton("Points", "sortCtorsPointsBtn", Sort.Points, this.toolbarState.sort, (value) => this.setSort(value as Sort), sortCtorsPointsBtnColor, sortCtorsHvrColor);
        appendButton("Name", "sortCtorsNameBtn", Sort.Name, this.toolbarState.sort, (value) => this.setSort(value as Sort), sortCtorsNameBtnColor, sortCtorsHvrColor);
        xOffset += textGap * 0.5;
        toolbar.append("path")
            .attr("stroke", sortCtorsBtnColor)
            .attr("fill", "none")
            .attr("stroke-width", textGap)
            .attr("d", d => d3.line()([[groupXStart, 0], [groupXStart, toolbarHeight], [xOffset, toolbarHeight], [xOffset, 0]]));

        // Toolbar: Link Buttons
        let linkBtnColor = this.toolbarState.show === Grouping.Both ? "#DC0000" : "#222";
        let linkHvrColor = this.toolbarState.show === Grouping.Both ? "#fa8" : "#222";
        xOffset += textGap * 2.5;
        groupXStart = xOffset;
        xOffset += textGap * 1.5;
        let linkCtorsBtnColor = this.toolbarState.show === Grouping.Constructors ? btnOffColor : this.toolbarState.show === Grouping.Drivers ? linkBtnColor : this.toolbarState.link !== Grouping.Drivers ? btnSelColor : btnOffColor;
        let linkAllBtnColor = this.toolbarState.show !== Grouping.Both ? linkBtnColor : this.toolbarState.link === Grouping.Both ? btnSelColor : btnOffColor;
        let linkDriversBtnColor = this.toolbarState.show === Grouping.Drivers ? btnOffColor : this.toolbarState.show === Grouping.Constructors ? linkBtnColor : this.toolbarState.link !== Grouping.Constructors ? btnSelColor : btnOffColor;
        appendButtonTitle("Link", this.toolbarState.show === Grouping.Both ? linkBtnColor : btnOffColor);
        appendButton("Constructors", "linkButtonCtors", Grouping.Constructors, this.toolbarState.link, (value) => this.setLink(value as Grouping), linkCtorsBtnColor, this.toolbarState.show === Grouping.Constructors ? linkCtorsBtnColor : linkHvrColor);
        appendButton("+", "linkButtonAll", Grouping.Both, this.toolbarState.link, (value) => this.setLink(value as Grouping), linkAllBtnColor, linkHvrColor, 24, 15);
        appendButton("Drivers", "linkButtonDrivers", Grouping.Drivers, this.toolbarState.link, (value) => this.setLink(value as Grouping), linkDriversBtnColor, this.toolbarState.show === Grouping.Drivers ? linkDriversBtnColor : linkHvrColor);
        xOffset += textGap * 0.5;
        toolbar.append("path")
            .attr("stroke", this.toolbarState.show === Grouping.Both ? linkBtnColor : btnOffColor)
            .attr("fill", "none")
            .attr("stroke-width", textGap)
            .attr("d", d => d3.line()([[groupXStart, 0], [groupXStart, toolbarHeight], [xOffset, toolbarHeight], [xOffset, 0]]));
    }

    onToolbarTouchStart = (e: React.TouchEvent<SVGSVGElement>) => {
        e.stopPropagation();
        this.touch.start(e);
    }

    onToolbarTouchEnd = (e: React.TouchEvent<SVGSVGElement>) => {
        this.touch.end(e);
    }

    onToolbarTouchMove = (e: React.TouchEvent<SVGSVGElement>) => {
        if (!this.touch.isDown) { return; }
        e.stopPropagation();
        let diff = this.touch.move(e);
        this.setToolbarX(this.toolbarX + diff.diffX);
        this.forceUpdate();
    }

    setToolbarX(toolbarX: number): void {
        this.toolbarX = toolbarX;
        this.toolbarX = Math.min(this.toolbarX, 0);
        this.toolbarX = Math.max(this.toolbarX, window.innerWidth - this.toolbarWidth);
    }

    render() {
        let now = window.performance.now();
        const width = window.innerWidth;
        const toolbarHeight = 42;
        if (this.firstRender) {
            // Wait for fonts to load
            this.firstRender = false;
            setTimeout(() => this.update(), 400);
        }
        if (!this.touch.isDown) {
            let timeDiff = now - this.lastPaintTime;
            this.setToolbarX(this.toolbarX + this.touch.touchXVelocity * timeDiff);
            this.touch.touchXVelocity *= 0.995; // why should this be different from the graph?
        }
        if (Math.abs(this.touch.touchXVelocity) > 0.001 || this.touch.touchXVelocities.length > 0) {
            setTimeout(() => this.forceUpdate());
        }
        this.lastPaintTime = now;
        return (
            <svg width={width} height={toolbarHeight} style={{ backgroundColor: "#222", display: "block" }}
                onTouchStart={this.onToolbarTouchStart} onTouchEnd={this.onToolbarTouchEnd} onTouchMove={this.onToolbarTouchMove}>
                <svg width={this.toolbarWidth} x={this.toolbarX} ref={(ref: SVGSVGElement) => this.toolbarRef = ref}></svg>
            </svg>
        );
    }
}