
export enum Grouping {
    Constructors,
    Both,
    Drivers
};

export enum Sort {
    Points,
    Name
};

export class ToolbarState {
    show = Grouping.Both;
    sort = Sort.Points;
    link = Grouping.Constructors;
}