import { NumberTransition } from "../../data/canvasData";
import { F1Driver, F1YearlyConstructor, F1YearlyData, F1YearlyDriver } from "../../data/firebaseData";

export function updateRanks(items: { points: number, rank: number }[]): void {
    let rank = 0;
    items
        .sort((item1, item2) => item2.points - item1.points)
        .forEach((item, i, arr) => {
            if (i === 0 || item.points !== arr[i - 1].points) {
                rank++;
            }
            item.rank = rank;
        });
}

export function sortConstructors(constructors: F1YearlyConstructor[], byPoints: boolean): void {
    constructors.sort((ctor1, ctor2) => {
        if (byPoints) {
            let pointDiff = ctor2.points - ctor1.points;
            if (pointDiff !== 0) {
                return pointDiff;
            }
        }
        return ctor1.name.localeCompare(ctor2.name);
    });
}

export function sortDrivers(drivers: F1Driver[], yearlyDrivers: F1YearlyDriver[], byPoints: boolean): void {
    yearlyDrivers.sort((driver1, driver2) => {
        if (byPoints) {
            let pointDiff = driver2.points - driver1.points;
            if (pointDiff !== 0) {
                return pointDiff;
            }
        }
        let name1 = getDriver(drivers, driver1.id).surname;
        let name2 = getDriver(drivers, driver2.id).surname;
        return name1.localeCompare(name2);
    });
}

export function getDriver(drivers: F1Driver[], id: string): F1Driver {
    return drivers.find(dr => dr.id === id) as F1Driver;
}

export function initTransitions(data: F1YearlyData) {
    for (let i = 0; i < data.years.length; i++) {
        const year = data.years[i];
        year.height = new NumberTransition();
        for (let j = 0; j < year.constructors.length; j++) {
            const ctor = year.constructors[j];
            ctor.pos = new NumberTransition();
        }
        for (let j = 0; j < year.drivers.length; j++) {
            const driver = year.drivers[j];
            for (let k = 0; k < driver.stints.length; k++) {
                const stint = driver.stints[k];
                stint.pos = new NumberTransition();
            }
        }
    }
}
