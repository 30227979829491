import { NormalizedTransition } from "../../data/canvasData";
import { isTouchDevice } from "../../utils/utils";
import leftMouse from "../../images/controls/left_mouse.png";
import middleMouse from "../../images/controls/middle_mouse.png";
import swipe from "../../images/controls/swipe.png";
import pinch from "../../images/controls/pinch.png";
import { paintRoundRect } from "../../utils/paintUtils";

export default class F1Controls {
    leftClickImg = new Image();
    scrollWheelImg = new Image();
    swipeImg = new Image();
    pinchImg = new Image();
    controlsTransition?: NormalizedTransition;

    constructor() {
        this.leftClickImg.src = leftMouse;
        this.scrollWheelImg.src = middleMouse;
        this.swipeImg.src = swipe;
        this.pinchImg.src = pinch;
    }

    transition(): void {
        if (!this.controlsTransition) {
            this.controlsTransition = new NormalizedTransition();
            this.controlsTransition.duration = 2000;
        }
    }

    isTransitioning(): boolean {
        return !!this.controlsTransition && !this.controlsTransition.isDone();
    }

    paint(ctx: CanvasRenderingContext2D): void {

        let controlsPos = 0;
        if (this.controlsTransition) {
            controlsPos = this.controlsTransition.update();
        }
        if (controlsPos < 1) {
            if (isTouchDevice()) {
                let margin = 5;
                let shadow = 5;
                let rectWidth = 145;
                let rectHeight = 125;
                let rectX = - (1 - controlsPos) * (rectWidth + margin + 2 * shadow);
                let rectY = - rectHeight - margin - 2 * shadow;
                let shadowX = rectX;
                let shadowY = rectY;
                let shadowWidth = rectWidth + 2 * shadow;
                let shadowHeight = rectHeight + 2 * shadow;
                ctx.fillStyle = '#0004';
                paintRoundRect(ctx, shadowX, shadowY, shadowWidth, shadowHeight, 5);
                rectX += shadow;
                rectY += shadow;
                ctx.fillStyle = '#dff';
                paintRoundRect(ctx, rectX, rectY, rectWidth, rectHeight, 5);
                ctx.fillStyle = '#111';
                ctx.font = '24px Arial';
                ctx.fillText("Pan:", rectX + 36, rectY + 45);
                ctx.fillText("Zoom:", rectX + 16, rectY + 95);
                let draw1 = () => ctx.drawImage(this.swipeImg, rectX + 90, rectY + 16, 40, 40);
                this.swipeImg.onload = () => draw1();
                draw1();
                let draw2 = () => ctx.drawImage(this.pinchImg, rectX + 90, rectY + 70, 40, 40);
                this.pinchImg.onload = () => draw2();
                draw2();
            } else {
                let margin = 5;
                let shadow = 5;
                let rectWidth = 165;
                let rectHeight = 165;
                let rectX = - (1 - controlsPos) * (rectWidth + margin + 2 * shadow);
                let rectY = - rectHeight - margin - 2 * shadow;
                let shadowX = rectX;
                let shadowY = rectY;
                let shadowWidth = rectWidth + 2 * shadow;
                let shadowHeight = rectHeight + 2 * shadow;
                ctx.fillStyle = '#0004';
                paintRoundRect(ctx, shadowX, shadowY, shadowWidth, shadowHeight, 5);
                rectX += shadow;
                rectY += shadow;
                ctx.fillStyle = '#dff';
                paintRoundRect(ctx, rectX, rectY, rectWidth, rectHeight, 5);
                ctx.fillStyle = '#111';
                ctx.font = '30px Arial';
                ctx.fillText("Pan:", rectX + 39, rectY + 55);
                ctx.fillText("Zoom:", rectX + 16, rectY + 130);
                let draw1 = () => ctx.drawImage(this.leftClickImg, rectX + 110, rectY + 16, 38, 60);
                this.leftClickImg.onload = () => draw1();
                draw1();
                let draw2 = () => ctx.drawImage(this.scrollWheelImg, rectX + 108, rectY + 90, 40, 60);
                this.scrollWheelImg.onload = () => draw2();
                draw2();
            }
        }
    }
}