import React from 'react';
import { NormalizedTransition, Rect } from '../../data/canvasData';
import { F1YearlyData } from '../../data/firebaseData';
import { Grouping, Sort, ToolbarState } from '../../data/toolbarData';
import { TouchData } from '../../data/touchData';
import { FpsPainter } from '../../paint/FpsPainter';
import { RacingGraphConstants, RacingGraphPainter } from '../../paint/RacingGraphPainter';
import { databaseRef } from '../../services/firebase';
import { initTransitions, sortConstructors, sortDrivers, updateRanks } from './dataUtils';
import F1Controls from './F1Controls';
import F1Footer from './F1Footer';
import './F1Graph.css';
import F1Toolbar from './F1Toolbar';

export default class F1Graph extends React.Component {
  canvasRef!: HTMLCanvasElement;
  canvasCtx!: CanvasRenderingContext2D;
  data: F1YearlyData = { drivers: [], years: [] };
  toolbarState = new ToolbarState();
  globalTransition = new NormalizedTransition();
  repaintRequested = true;
  zoom = 0;
  canvasRatio = window.devicePixelRatio;
  lastPaintTime = 0;
  touch = new TouchData();
  controls = new F1Controls();
  graphPainter = new RacingGraphPainter();
  fpsPainter = new FpsPainter();

  componentDidMount() {
    this.zoom = window.innerWidth < 500 ? 0.8 : 1;
    databaseRef.on('value', (snapshot) => {
      let data = snapshot.val() as F1YearlyData;
      this.onData(data);
    }, (errorObject) => {
      console.log("Error receiving data", errorObject);
    });

    this.canvasCtx = this.canvasRef.getContext("2d") as CanvasRenderingContext2D;

    window.addEventListener('resize', () => this.onResize());
  }

  onData(data: F1YearlyData) {
    this.data = data;
    initTransitions(data);
    this.initRanks();
    this.graphPainter.setData(data);
    this.onToolbarChanged();
    this.setZoom(this.zoom); // Enforce limits
    this.drawCanvas();
  }

  onResize(): void {
    this.onToolbarChanged();
    this.setZoom(this.zoom); // Enforce limits
    this.repaint();
  }

  initRanks(): void {
    for (let i = 0; i < this.data.years.length; i++) {
      updateRanks(this.data.years[i].constructors)
      updateRanks(this.data.years[i].drivers)
    }
  }

  updateSort(): void {
    // TODO Only if sorting changed
    let data = this.data;
    let sortByPoints = this.toolbarState.sort === Sort.Points;
    for (let i = 0; i < data.years.length; i++) {
      sortConstructors(data.years[i].constructors, sortByPoints);
    }
    for (let i = 0; i < data.years.length; i++) {
      sortDrivers(data.drivers, data.years[i].drivers, sortByPoints);
    }
  }

  onToolbarChanged(): void {
    // TODO
    // Refactoring
    // Download and do import for flag images (since they should then all be inlined if < 10KB)
    // Add Formula 1 - 2021 graph
    // More Formula 1 seasons data 
    // Momentum for footer
    // Highlight & show tooltips on hover
    //    Invert black/white for constructors?
    //    Opacity 1 for all links
    //    Keep state on click (for mobile)
    // Keep center fixed when zooming in with mouse
    // Add URL routes for toolbar states, then make Home images clickable with correct routes
    // Add race number grid
    // Don't scroll year headers out of view
    // Up-down arrows for position gained/lossed between years?
    // Disabled buttons are still clickable
    // Draw lines in same order even if changing Sort
    // Add accounting check for points (make number red?)
    // Adds
    this.updateSort();

    let showCtors = this.toolbarState.show !== Grouping.Drivers;
    let showDrivers = this.toolbarState.show !== Grouping.Constructors;
    let linkCtors = this.toolbarState.link !== Grouping.Drivers;
    let linkDrivers = this.toolbarState.link !== Grouping.Constructors;

    this.graphPainter.update(showCtors, showDrivers, linkCtors, linkDrivers);
  }

  drawCanvas() {
    this.repaintRequested = false;
    let paintStartTime = window.performance.now();
    if (!this.canvasRef || !this.canvasCtx) {
      this.onToolbarChanged();
      this.repaint();
      return;
    }
    this.globalTransition.update();
    let ctx = this.canvasCtx;

    this.globalTransition.transition(this.graphPainter.totalHeight);

    // enforce zoom limits
    let zoom = this.zoom;

    // enfore pan limits
    let now = window.performance.now();
    let timeDiff = 0;
    if (!this.isDown) {
      if (this.lastPaintTime !== 0) {
        timeDiff = now - this.lastPaintTime;
      }
      this.netPanningX += (this.touch.touchXVelocity * timeDiff);
      this.netPanningY += (this.touch.touchYVelocity * timeDiff);
    }
    this.lastPaintTime = now;
    if (this.netPanningX < (window.innerWidth / zoom - this.graphPainter.totalWidth)) this.netPanningX = (window.innerWidth / zoom - this.graphPainter.totalWidth);
    if (this.netPanningX > 0) this.netPanningX = 0;
    if (this.netPanningY < (this.canvasHeight / zoom - this.graphPainter.totalHeight.current)) this.netPanningY = (this.canvasHeight / zoom - this.graphPainter.totalHeight.current);
    if (this.netPanningY > 0) this.netPanningY = 0;

    ctx.resetTransform();
    ctx.clearRect(0, 0, this.canvasRef.width, this.canvasRef.height);
    ctx.save();
    ctx.scale(zoom * this.canvasRatio, zoom * this.canvasRatio);
    ctx.translate(this.netPanningX + RacingGraphConstants.margin, this.netPanningY + RacingGraphConstants.margin);

    let visibleMargin = -RacingGraphConstants.margin + 0; // change 0 for debugging 
    let visibleWidth = window.innerWidth / zoom - 2 * visibleMargin;
    let visibleHeight = this.canvasHeight / zoom - 2 * visibleMargin;
    let visibleX = -this.netPanningX + visibleMargin;
    let visibleY = -this.netPanningY + visibleMargin;
    let visible = new Rect(visibleX, visibleY, visibleWidth, visibleHeight);

    this.graphPainter.paint(ctx, visible, this.globalTransition, () => this.repaint());
    ctx.restore();

    ctx.save();
    ctx.scale(this.canvasRatio, this.canvasRatio);
    ctx.translate(window.innerWidth, this.canvasHeight);
    this.controls.paint(ctx);
    ctx.restore();

    this.fpsPainter.paint(ctx, paintStartTime);

    if (!this.touch.isDown) {
      this.touch.touchXVelocity *= 0.95;
      this.touch.touchYVelocity *= 0.95;
    }
    if (!this.globalTransition.isDone()
      || Math.abs(this.touch.touchXVelocity) > 0.001
      || Math.abs(this.touch.touchYVelocity) > 0.001
      || this.controls.isTransitioning()
      || this.touch.touchXVelocities.length > 0
      || this.touch.touchYVelocities.length > 0
    ) {
      this.repaint();
    }
  }

  setZoom(zoom: number): void {
    let viewMinRatio = window.innerWidth / this.graphPainter.totalWidth;
    this.zoom = Math.max(zoom, viewMinRatio);
    this.zoom = Math.min(this.zoom, 2.0);
  }

  canvasStartX = 0;
  canvasStartY = 0;
  isDown = false;
  netPanningX = -10000; // start at right
  netPanningY = 0;
  canvasHeight = 0;
  onCanvasMouseDown = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    this.canvasStartX = e.clientX;
    this.canvasStartY = e.clientY;
    this.isDown = true;
  }

  onCanvasTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    e.stopPropagation();
    this.touch.start(e);
  }

  onCanvasMouseUp = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    this.isDown = false;
  }

  onCanvasTouchEnd = (e: React.TouchEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.touch.end(e);
  }

  onCanvasMouseMove = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!this.isDown) { return; }
    e.preventDefault();
    e.stopPropagation();
    let mouseX = e.clientX;
    let mouseY = e.clientY;
    var dx = mouseX - this.canvasStartX;
    var dy = mouseY - this.canvasStartY;
    this.canvasStartX = mouseX;
    this.canvasStartY = mouseY;
    this.netPanningX += dx / this.zoom;
    this.netPanningY += dy / this.zoom;
    this.controls.transition();
    this.repaint();
  }

  onCanvasTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    if (!this.touch.isDown) { return; }
    e.stopPropagation();
    let diff = this.touch.move(e);
    if (this.touch.multiTouch) {
      let widthBefore = window.innerWidth / this.zoom;
      let heightBefore = window.innerHeight / this.zoom;
      let avgSize = (widthBefore + heightBefore) / 2;
      this.setZoom(this.zoom - diff.diffPinch / avgSize);
      let widthAfter = window.innerWidth / this.zoom;
      let heightAfter = window.innerHeight / this.zoom;
      this.netPanningX += (widthAfter - widthBefore) / 2;
      this.netPanningY += (heightAfter - heightBefore) / 2;
    } else {
      this.netPanningX += diff.diffX / this.zoom;
      this.netPanningY += diff.diffY / this.zoom;
    }
    this.controls.transition();
    this.repaint();
  }

  onCanvasMouseWheel = (e: React.WheelEvent<HTMLCanvasElement>) => {
    e.stopPropagation();
    if (e.deltaY > 0) {
      this.setZoom(this.zoom - 0.1);
    } else {
      this.setZoom(this.zoom + 0.1);
    }
    this.controls.transition();
    this.repaint();
  }

  onToolbarState(state: ToolbarState): void {
    this.toolbarState = state;
    this.globalTransition.restart();
    this.onToolbarChanged();
    this.repaint();
  }

  repaint(): void {
    if (!this.repaintRequested) {
      this.repaintRequested = true;
      requestAnimationFrame(() => this.drawCanvas());
    }
  }

  render() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const titleHeight = 48;
    const toolbarHeight = 42;
    const footerHeight = 40;
    this.canvasHeight = height - titleHeight - toolbarHeight - footerHeight;
    return (
      <div className="F1Graph" style={{ height: height }}>
        <div className="title" style={{ height: titleHeight }}>
          FORMULA 1 {/* Note that FORMULA must be upper case according to terms of use */}
        </div>
        <F1Toolbar onState={(state) => this.onToolbarState(state)} />
        <canvas ref={(ref: HTMLCanvasElement) => this.canvasRef = ref}
          width={width * this.canvasRatio} height={this.canvasHeight * this.canvasRatio} style={{ width: width + "px", height: this.canvasHeight + "px", backgroundColor: "#262626", display: "block" }}
          onMouseDown={this.onCanvasMouseDown} onMouseUp={this.onCanvasMouseUp} onMouseMove={this.onCanvasMouseMove} onMouseOut={this.onCanvasMouseUp}
          onTouchStart={this.onCanvasTouchStart} onTouchEnd={this.onCanvasTouchEnd} onTouchMove={this.onCanvasTouchMove}
          onWheel={this.onCanvasMouseWheel}>
        </canvas>
        <F1Footer />
      </div>
    );
  }
}
