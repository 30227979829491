import firebase from 'firebase/app'
import "firebase/database"
import "firebase/analytics"
import { consentService } from './consent';
const config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PID,
    storageBucket: process.env.REACT_APP_SB,
    messagingSenderId: process.env.REACT_APP_SID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MID
};
firebase.initializeApp(config);
if (consentService.isConsented()) {
    firebase.analytics();
}
export const databaseRef = firebase.database().ref();
export default firebase;
