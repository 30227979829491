import { timeout } from 'd3';
import React from 'react';
import './About.css';
import firebase from "../../images/about/firebase.svg";
import react from "../../images/about/react.svg";
import HTML5 from "../../images/about/HTML5.svg";
import d3 from "../../images/about/d3.svg";
import typescript from "../../images/about/typescript.svg";

export default class About extends React.Component<{}, { beIndex: number }> {
  be = ["funny", "grateful", "kind"];
  lllAnim = 4000;
  lllTime = window.performance.now() - this.lllAnim / 2;
  lllArrShow: number[] = [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19];
  lllArrHide: number[] = [];

  constructor() {
    super({});
    this.state = {
      beIndex: -1
    };
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      this.update();
    });
  }

  update() {
    let idx = Math.round(window.performance.now() / (3 * 1000));
    this.setState({ beIndex: idx });
    timeout(() => this.update(), 100);
  }

  getBeClass(i: number): string {
    let beTotal = 17;
    let className = "be ";
    if (this.state.beIndex % beTotal === i) {
      className += "beIn";
    } else if ((this.state.beIndex - 1) % beTotal === i) {
      className += "beOut";
    } else {
      className += "hidden";
    }
    return className;
  }

  onClickLLL() {
    this.lllTime = window.performance.now();
  }

  render() {
    let lllTimeDiff = (window.performance.now() - this.lllTime) / this.lllAnim;
    let toShow = 0;
    if (lllTimeDiff < 1) {
      let max = 30;
      toShow = max - Math.abs(Math.round((2 * max * lllTimeDiff)) - max);
      toShow = Math.min(17, toShow);
    }
    while (toShow > this.lllArrShow.length) {
      let i = Math.round(Math.random() * this.lllArrHide.length);
      let removed = this.lllArrHide.splice(i, 1)[0];
      if (removed) {
        this.lllArrShow.push(removed);
      }
    }
    while (toShow < this.lllArrShow.length) {
      let i = Math.round(Math.random() * this.lllArrShow.length);
      let removed = this.lllArrShow.splice(i, 1)[0];
      if (removed) {
        this.lllArrHide.push(removed);
      }
    }
    let lllFullText = "Live - Laugh - Love";
    let lllText = "";
    for (let i = 0; i < lllFullText.length; i++) {
      if (i === 0 || i === 7 || i === 15) {
        lllText += 'L';
      } else {
        if (this.lllArrShow.includes(i)) {
          lllText += lllFullText.charAt(i);
        }
      }
    }
    let hue = 0;
    let hues: number[] = [];
    for (let i = 0; i < 17; i++) {
      do {
        hue += 81;
        hue = hue % 360;
      } while (hue > 200 && hue < 300);
      hues.push(hue);
    }
    return (
      <div style={{ width: window.innerWidth, height: window.innerHeight }}>
        <div className="title" style={{ height: 48 }}>
          <a href="/about">About</a>
        </div>
        {/* <div className="about" style={{ maxWidth: 1024, height: window.innerHeight - 48 }}> */}
        <div className="about" style={{ maxWidth: 1024, height: window.innerHeight - 80 }}>
          <div className="inner">
            <div style={{ display: "flex", paddingLeft: 20 }}>
              <div style={{ display: "flex", marginRight: 6 }}>Be</div>
              <div style={{ display: "flex", position: "relative", width: 280 }}>abc
                <div className={this.getBeClass(0)} style={{ color: "hsl(" + hues[0] + ", 100%, 50%)" }}>funny.</div>
                <div className={this.getBeClass(1)} style={{ color: "hsl(" + hues[1] + ", 100%, 50%)" }}>strong.</div>
                <div className={this.getBeClass(2)} style={{ color: "hsl(" + hues[2] + ", 100%, 50%)" }}>grateful.</div>
                <div className={this.getBeClass(3)} style={{ color: "hsl(" + hues[3] + ", 100%, 50%)" }}>adventurous.</div>
                <div className={this.getBeClass(4)} style={{ color: "hsl(" + hues[4] + ", 100%, 50%)" }}>mindful.</div>
                <div className={this.getBeClass(5)} style={{ color: "hsl(" + hues[5] + ", 100%, 50%)" }}>kind.</div>
                <div className={this.getBeClass(6)} style={{ color: "hsl(" + hues[6] + ", 100%, 50%)" }}>courageous.</div>
                <div className={this.getBeClass(7)} style={{ color: "hsl(" + hues[7] + ", 100%, 50%)" }}>healthy.</div>
                <div className={this.getBeClass(8)} style={{ color: "hsl(" + hues[8] + ", 100%, 50%)" }}>brave.</div>
                <div className={this.getBeClass(9)} style={{ color: "hsl(" + hues[9] + ", 100%, 50%)" }}>inspirational.</div>
                <div className={this.getBeClass(10)} style={{ color: "hsl(" + hues[10] + ", 100%, 50%)" }}>wise.</div>
                <div className={this.getBeClass(11)} style={{ color: "hsl(" + hues[11] + ", 100%, 50%)" }}>joyful.</div>
                <div className={this.getBeClass(12)} style={{ color: "hsl(" + hues[12] + ", 100%, 50%)" }}>enthusiastic.</div>
                <div className={this.getBeClass(13)} style={{ color: "hsl(" + hues[13] + ", 100%, 50%)" }}>forgiving.</div>
                <div className={this.getBeClass(14)} style={{ color: "hsl(" + hues[14] + ", 100%, 50%)" }}>optimistic.</div>
                <div className={this.getBeClass(15)} style={{ color: "hsl(" + hues[15] + ", 100%, 50%)" }}>fearless.</div>
                <div className={this.getBeClass(16)} style={{ color: "hsl(" + hues[16] + ", 100%, 50%)" }}>the change you want to see.</div>
              </div>
            </div>
            <div>
              <p>This solo project was made possible by combining the <span style={{ fontSize: "80%" }}>❤️</span> of <b>racing</b>, <b>data viz</b> and <b>coding</b>.</p>
              <p>Hope you find something here that you enjoy. If not, hope you see something else today that makes you smile.</p>
              <p>For issues found with the site or data please send an email to <b><i><a href="mailto:racingvizsite+champ@gmail.com">racingvizsite+champ@gmail.com</a></i></b>.</p>
              <p>Suggestions are also welcome (tyvm!).</p>
              <p style={{ fontSize: "80%", textAlign: "center", margin: 25 }}><i>Dedicated to the ever cheerful and endlessly caring love of my life, as well as three weird little fluffy funny pogu dogo wogos.</i><span className="smiley">🐶🐶🐶</span></p>
            </div>
            <hr />
            <div style={{ textAlign: "center" }}>
              <div className="lll" onClick={() => this.onClickLLL()}><i>{lllText}</i></div>
            </div>
            <hr />
            <div style={{ textAlign: "center" }}>
              <a href="https://firebase.com"><img alt="Firebase" src={firebase} height="50px" style={{ margin: 10 }}></img></a>
              <a href="https://reactjs.org"><img alt="React" src={react} height="38px" style={{ background: "#111", paddingTop: 6, paddingBottom: 6, borderRadius: 4, margin: 10 }}></img></a>
              <a href="https://www.w3.org/html/wg/spec/the-canvas-element.html"><img alt="HTML5 Canvas" src={HTML5} height="38px" style={{ background: "#333", padding: 6, borderRadius: 4, margin: 10 }}></img></a>
              <a href="https://d3js.org"><img alt="D3" src={d3} height="38px" style={{ background: "#222", padding: 6, borderRadius: 4, margin: 10 }}></img></a>
              <a href="https://www.typescriptlang.org"><img alt="Typescript" src={typescript} height="50px" style={{ margin: 10 }}></img></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
