import firebase from 'firebase/app';
import React from 'react';
import { consentService } from '../../services/consent';
import './Consent.css';

export default class Consent extends React.Component {

  showConsent(): boolean {
    return !consentService.isConsented();
  }

  onAccept(): void {
    consentService.setConsented();
    firebase.analytics();
    this.setState({});
  }

  render() {
    return (
      <div className="consent" style={{ display: this.showConsent() ? 'flex' : 'none' }}>
        {/* <span>This site uses cookies to enhance the user experience. For more details see our <a href="/privacy">Privacy Policy</a></span> */}
        <span>By clicking “Accept”, you agree Racing Viz can store cookies on your device and disclose information in accordance with our <a href="/privacy">Privacy Policy</a>.</span> {/*from stackexchange*/}
        <div>
          <button onClick={() => this.onAccept()}>Accept</button>
          {/* <button>Customize</button> */}
          {/* <button>Cancel</button> */}
        </div>
      </div>
    );
  }
}
