import React from 'react';
import './PrivacyPolicy.css';

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div style={{ width: window.innerWidth, height: window.innerHeight }}>
        <div className="title" style={{ height: 48 }}>
          Privacy
        </div>
        <div className="pp" style={{ maxWidth: 1024, height: window.innerHeight - 48 }}>
          <h1>Privacy Policy</h1>
          <div>
            <p>At Racing Viz, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Racing Viz and how we use it.</p>

            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at <a href="mailto:racingvizsite+pp@gmail.com">racingvizsite+pp@gmail.com</a>.</p>

            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Racing Viz. This policy is not applicable to any information collected offline or via channels other than this website.</p>

            <h2>Consent</h2>

            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

            <h2>Information we collect</h2>

            <p>We use cookies to track anonymized user behaviour (with Google Analytics).</p>{/* and for showing ads (with Google Adsense).</p> */}
            <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
            <p>Other than that, we do not actively process any personal information on this website.</p>
          </div>
        </div>
      </div>
    );
  }
}