import { mean } from "d3";

export class TouchData {
    touchTime = 0;
    startX = 0;
    startY = 0;
    pinchDistance = 0;
    multiTouch = false;
    isDown = true;
    touchXVelocities: number[] = [];
    touchYVelocities: number[] = [];
    touchXVelocity = 0;
    touchYVelocity = 0;

    start(e: React.TouchEvent<Element>): void {
        this.isDown = true;
        this.touchTime = window.performance.now();
        this.startX = e.touches[0].clientX;
        this.startY = e.touches[0].clientY;
        if (e.touches.length > 1) {
            let p1 = e.touches[0];
            let p2 = e.touches[1];
            this.pinchDistance = Math.sqrt(Math.pow(p1.clientX - p2.clientX, 2) + Math.pow(p1.clientY - p2.clientY, 2));
            this.multiTouch = true;
        } else {
            this.multiTouch = false;
        }
        this.touchXVelocities = [];
        this.touchYVelocities = [];
    }

    move(e: React.TouchEvent<Element>): { diffX: number, diffY: number, diffPinch: number } {
        let mouseX = e.touches[0].clientX;
        let mouseY = e.touches[0].clientY;
        var dx = mouseX - this.startX;
        var dy = mouseY - this.startY;
        this.startX = mouseX;
        this.startY = mouseY;
        let diffPinch = 0;
        if (e.touches.length > 1) {
            let p1 = e.touches[0];
            let p2 = e.touches[1];
            let pinchDistance = Math.sqrt(Math.pow(p1.clientX - p2.clientX, 2) + Math.pow(p1.clientY - p2.clientY, 2));
            diffPinch = this.pinchDistance - pinchDistance;
            this.pinchDistance = pinchDistance;
            this.multiTouch = true;
            this.touchXVelocity = 0;
            this.touchYVelocity = 0;
            this.touchXVelocities = [];
            this.touchYVelocities = [];
        } else {
            let now = window.performance.now();
            let touchXVelocity = dx / (now - this.touchTime);
            let touchYVelocity = dy / (now - this.touchTime);
            this.touchXVelocities.push(touchXVelocity);
            this.touchYVelocities.push(touchYVelocity);
            this.touchXVelocities.splice(0, this.touchXVelocities.length - 5);
            this.touchYVelocities.splice(0, this.touchYVelocities.length - 5);
            this.touchTime = now;
        }
        return { diffX: dx, diffY: dy, diffPinch: diffPinch };
    }

    end(e: React.TouchEvent<Element>): void {
        this.isDown = false;
        if (!this.multiTouch) {
            if (this.touchXVelocities.length > 0 && this.touchYVelocities.length > 0) {
                this.touchXVelocity = mean(this.touchXVelocities) as number;
                this.touchYVelocity = mean(this.touchYVelocities) as number;
            } else {
                this.touchXVelocity = 0;
                this.touchYVelocity = 0;
            }
        }
        this.touchXVelocities = [];
        this.touchYVelocities = [];
    }
}