import React from "react";

export default class F1Toolbar extends React.Component {
    footerRef!: SVGSVGElement;
    footerWidth = 0;
    footerStartX = 0;
    footerTouchX = 0;
    footerX = 0;
    footerMargin = 4;
    isDown = false;

    componentDidMount(): void {
        this.onResize();
        window.addEventListener('resize', () => this.onResize());
    }

    onResize(): void {
        let footerText = this.footerRef.firstChild as SVGTextElement;
        this.footerWidth = footerText.getBBox().width + 2 * this.footerMargin;
        if (this.footerWidth > window.innerWidth) {
            this.footerX = -(this.footerWidth - window.innerWidth) / 2;
        } else {
            this.footerX = 0;
        }
        this.forceUpdate();
    }

    onFooterTouchStart = (e: React.TouchEvent<SVGSVGElement>) => {
        e.stopPropagation();
        if (e.touches.length === 1) {
            this.footerStartX = this.footerX;
            this.footerTouchX = e.touches[0].clientX;
            this.isDown = true;
        }
    }

    onFooterTouchEnd = (e: React.TouchEvent<SVGSVGElement>) => {
        this.isDown = false;
    }

    onFooterTouchMove = (e: React.TouchEvent<SVGSVGElement>) => {
        if (!this.isDown) { return; }
        e.stopPropagation();
        this.footerX = this.footerStartX + e.touches[0].clientX - this.footerTouchX;
        let footerText = this.footerRef.firstChild as SVGTextElement;
        let actualFooterWidth = footerText.getBBox().width + 2 * this.footerMargin;
        this.footerX = Math.min(this.footerX, 0);
        this.footerX = Math.max(this.footerX, window.innerWidth - actualFooterWidth);
        this.forceUpdate();
    }

    render() {
        const width = window.innerWidth;
        const footerHeight = 50;
        return (
            <svg width={width} height={footerHeight} style={{ color: "#eee", background: "#111", fontSize: 10, fontWeight: "bold", colorScheme: "dark" }}
                onTouchStart={this.onFooterTouchStart} onTouchEnd={this.onFooterTouchEnd} onTouchMove={this.onFooterTouchMove}>
                <svg x={this.footerX} width={Math.max(width, this.footerWidth)} ref={(ref: SVGSVGElement) => this.footerRef = ref}>
                    <text x={Math.max(this.footerMargin, (width - this.footerWidth) / 2)} y="30%" fill="white">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Copyright &copy; {new Date().getFullYear()}, RacingViz.  Last updated 26 September 2021.
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </text>
                    <text x={Math.max(this.footerMargin, (width - this.footerWidth) / 2)} y="60%" fill="white">This website is unofficial and is not associated in any way with the Formula 1 companies. F1, FORMULA ONE, FORMULA 1, FIA FORMULA ONE WORLD CHAMPIONSHIP, GRAND PRIX and related marks are trade marks of Formula One Licensing B.V.</text>
                </svg>
            </svg>
        );
    }
}