
export class FpsPainter {
    showFps = false;
    fpsArr: number[] = [];
    paintCount = 0;

    paint(ctx: CanvasRenderingContext2D, paintStartTime: number): void {
        if (this.showFps) {
            let paintTime = window.performance.now() - paintStartTime;
            let fps = 1000 / paintTime;
            this.fpsArr.push(fps);
            if (this.fpsArr.length > 10) {
                this.fpsArr.shift();
            }
            let fpsAvg = Math.round(this.fpsArr.reduce((a, b) => a + b) / this.fpsArr.length);
            this.paintCount++;
            ctx.fillStyle = "white";
            ctx.font = '16px Arial';
            ctx.fillText("FPS: " + fpsAvg, 20, 20);
            ctx.fillText("Paints: " + this.paintCount, 20, 40);
        }
    }
}