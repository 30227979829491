import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import About from './components/About/About';
import Consent from './components/Consent/Consent';
import F1Graph from './components/F1Graph/F1Graph';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

export default class App extends React.Component {

  componentDidMount() {
    window.addEventListener('resize', () => this.onResize());
  }

  onResize() {
    this.forceUpdate();
  }

  render() {
    return (
      <BrowserRouter>
        <main>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/f1" component={F1Graph} exact />
            <Route path="/about" component={About} exact />
            <Route path="/privacy" component={PrivacyPolicy} exact />
            <Redirect to="/" />
          </Switch>
        </main>
        <Navbar />
        <Consent />
      </BrowserRouter>
    );
  }
}
