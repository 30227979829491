import { getDriver } from "../components/F1Graph/dataUtils";
import { CachedImg, DriverCtorGroup, NormalizedTransition, NumberTransition, Rect } from "../data/canvasData";
import { DriverStint, F1Year, F1YearlyConstructor, F1YearlyData, F1YearlyDriver } from "../data/firebaseData";

interface PaintContext {
    ctx: CanvasRenderingContext2D;
    visible: Rect;
    globalTransition: NormalizedTransition;
    repaint: () => void;
}

export class RacingGraphConstants {
    static yearXGap = 100;
    static raceWidth = 14;
    static raceHeight = 6;
    static yearHeight = 30;
    static flagWidth = 32;
    static flagHeight = 12;
    static pointsWidth = 60;
    static ctorHeight = 26;
    static ctorSlashWidth = 20;
    static driverHeight = 20;
    static flagGap = 8;
    static ctorGap = 10;
    static yearGap = 100;
    static margin = 10;
}

export class RacingGraphPainter {
    data!: F1YearlyData;
    driverCtorGroups: DriverCtorGroup[] = [];
    totalWidth = 0;
    totalHeight = new NumberTransition();
    imgCache: CachedImg[] = [];
    ctorAlphaTransition = new NumberTransition();
    ctorLineAlphaTransition = new NumberTransition();
    driverAlphaTransition = new NumberTransition();
    driverLineAlphaTransition = new NumberTransition();
    paintCtx!: PaintContext;

    setData(data: F1YearlyData): void {
        this.data = data;
        this.updateWidths();
    }

    updateWidths(): void {
        let yearXMax = this.updateYearWidthsAndOffsets();
        this.totalWidth = yearXMax + RacingGraphConstants.margin * 2;
    }

    updateYearWidthsAndOffsets(): number {
        let yearXOffset = 0;
        for (let i = 0; i < this.data.years.length; i++) {
            const year = this.data.years[i];
            year.width = year.raceCount * RacingGraphConstants.raceWidth + RacingGraphConstants.flagWidth + RacingGraphConstants.pointsWidth;
            year.x = yearXOffset;
            yearXOffset += year.width + RacingGraphConstants.yearGap;
        }
        yearXOffset -= RacingGraphConstants.yearGap;
        return yearXOffset;
    }

    update(showCtors: boolean, showDrivers: boolean, linkCtors: boolean, linkDrivers: boolean) {
        this.totalHeight.setAfter(0);

        let ctorLineAlpha = showCtors && (linkCtors || !showDrivers) ? 1 : 0;
        this.ctorLineAlphaTransition.setAfter(ctorLineAlpha);
        let driverLineAlpha = showDrivers && (linkDrivers || !showCtors) ? 1 : 0;
        this.driverLineAlphaTransition.setAfter(driverLineAlpha);

        let ctorAlpha = showCtors ? 1 : 0;
        this.ctorAlphaTransition.setAfter(ctorAlpha);

        let driverAlpha = showDrivers ? 1 : 0;
        this.driverAlphaTransition.setAfter(driverAlpha);

        this.updateDriverCtorGroups(showCtors, showDrivers);
    }

    updateDriverCtorGroups(showCtors: boolean, showDrivers: boolean): void {
        let driverGap = showDrivers ? 10 : 0;
        let driverHeight = showDrivers ? 20 : 0;
        let driverCtorGroups: DriverCtorGroup[] = [];
        for (let i = 0; i < this.data.years.length; i++) {
            const year = this.data.years[i];
            let next = { offset: RacingGraphConstants.yearHeight };
            if (showCtors) {
                for (let j = 0; j < year.constructors.length; j++) {
                    const constructor = year.constructors[j];
                    constructor.raceCount = year.raceCount;
                    next.offset += RacingGraphConstants.ctorGap;
                    constructor.pos.setAfter(next.offset);
                    next.offset += RacingGraphConstants.ctorHeight;
                    let drivers = year.drivers.filter(d => d.stints.some(s => s.constructorId === constructor.id));
                    let toCtor = () => constructor;
                    driverCtorGroups.push(...this.createDriverCtorGroups(year, drivers,
                        driverGap, driverHeight, next, toCtor, toCtor));
                }
            } else {
                for (let j = 0; j < year.constructors.length; j++) {
                    // This is so that transition interruptions work
                    const constructor = year.constructors[j];
                    constructor.pos.before = constructor.pos.current;
                }
                let drivers = year.drivers;
                let stintToCtor = (stint: DriverStint) => year.constructors.find(c => stint.constructorId === c.id) as F1YearlyConstructor;
                let driverToCtor = (yDriver: F1YearlyDriver) => year.constructors.find(c => yDriver.stints[0].constructorId === c.id) as F1YearlyConstructor;
                driverCtorGroups.push(...this.createDriverCtorGroups(year, drivers,
                    driverGap, driverHeight, next, stintToCtor, driverToCtor));
            }
            let heightAfter = next.offset + RacingGraphConstants.yearHeight / 2;
            year.height.setAfter(heightAfter);
            this.totalHeight.setAfter(Math.max(this.totalHeight.after, year.height.after));
        }
        this.driverCtorGroups = driverCtorGroups;
    }

    createDriverCtorGroups(year: F1Year, drivers: F1YearlyDriver[],
        driverGap: number, driverHeight: number, next: { offset: number },
        stintToCtor: (stint: DriverStint) => F1YearlyConstructor,
        driverToCtor: (yDriver: F1YearlyDriver) => F1YearlyConstructor): DriverCtorGroup[] {
        let driverCtorGroups: DriverCtorGroup[] = [];
        for (let j = 0; j < drivers.length; j++) {
            const yDriver = drivers[j];
            next.offset += driverGap;
            for (let l = 0; l < yDriver.stints.length; l++) {
                let stint = yDriver.stints[l];
                let constructor = stintToCtor(stint);
                if (stint.constructorId === constructor.id) {
                    stint.pos.setAfter(next.offset);
                    stint.constructor = constructor;
                }
            }
            let constructor = driverToCtor(yDriver);
            let driverCtorGroup = this.createDriverCtorGroup(year, constructor, yDriver, next.offset);
            driverCtorGroups.push(driverCtorGroup);
            next.offset += driverHeight;
        }
        return driverCtorGroups;
    }

    createDriverCtorGroup(year: F1Year, constructor: F1YearlyConstructor, yDriver: F1YearlyDriver, nextOffset: number) {
        let driver = getDriver(this.data.drivers, yDriver.id);
        let prevGroup = this.driverCtorGroups.find(g =>
            g.year.year === year.year &&
            g.constructor.id === constructor.id &&
            g.driver.id === driver.id
        );
        let posAfter = nextOffset;
        let pos;
        if (prevGroup) {
            pos = prevGroup.pos;
            pos.setAfter(posAfter);
        } else {
            pos = new NumberTransition(posAfter, posAfter);
        }
        return {
            year: year, constructor: constructor, yDriver: yDriver, driver: driver, points: yDriver.points, pos: pos
        }
    }

    paint(ctx: CanvasRenderingContext2D, visible: Rect, globalTransition: NormalizedTransition, repaint: () => void): void {
        this.paintCtx = { ctx, visible, globalTransition, repaint };

        globalTransition.transition(this.ctorAlphaTransition);
        for (let i = 0; i < this.data.years.length; i++) {
            const year = this.data.years[i];
            for (let index = 0; index < year.constructors.length; index++) {
                const ctor = year.constructors[index];

                globalTransition.transition(ctor.pos);
            }
        }

        globalTransition.transition(this.driverAlphaTransition);
        for (let i = 0; i < this.driverCtorGroups.length; i++) {
            const driverGroup = this.driverCtorGroups[i];

            globalTransition.transition(driverGroup.pos);
        }

        // Constructor Lines
        globalTransition.transition(this.ctorLineAlphaTransition);
        this.paintConstructorLines();

        // Driver Lines
        globalTransition.transition(this.driverLineAlphaTransition);
        this.paintDriverLines();

        let yearXNext = 0;
        for (let i = 0; i < this.data.years.length; i++) {
            const year = this.data.years[i];
            let yearWidth = year.raceCount * RacingGraphConstants.raceWidth + RacingGraphConstants.flagWidth + RacingGraphConstants.pointsWidth;

            ctx.globalAlpha = 1;
            globalTransition.transition(year.height);
            this.paintColumn(String(year.year), RacingGraphConstants.yearHeight, yearXNext, yearWidth, year.height.current);

            this.paintDrivers(year, yearXNext, year.width);
            this.paintDriverStints(year, yearXNext + RacingGraphConstants.flagWidth, RacingGraphConstants.driverHeight);

            this.paintConstructorNamesAndPoints(year, yearXNext);

            yearXNext += yearWidth + RacingGraphConstants.yearXGap;
        }
    }

    paintCurvedLine(lineRect: Rect, lineWidth: number, colorStart: string, colorEnd: string): void {
        let ctx = this.paintCtx.ctx;
        let visible = this.paintCtx.visible;
        let x0 = lineRect.x0;
        let y0 = lineRect.y0;
        let x1 = lineRect.x1;
        let y1 = lineRect.y1;
        let lineHalf = lineWidth / 2;
        let gradientMargin = lineRect.width * 0.1;
        if (x0 < visible.x1 && x1 > visible.x0 && Math.min(y0, y1) - lineHalf < visible.y1 && Math.max(y0, y1) + lineHalf > visible.y0) {
            let gradient = ctx.createLinearGradient(x0 + gradientMargin, y0, x1 - gradientMargin, y1);
            gradient.addColorStop(0, colorStart);
            gradient.addColorStop(1, colorEnd);
            let xHalf = x0 + lineRect.width * 0.5;
            ctx.strokeStyle = gradient;
            ctx.lineCap = "square";
            ctx.lineWidth = lineWidth;
            ctx.beginPath();
            ctx.moveTo(x0, y0);
            ctx.bezierCurveTo(xHalf, y0, xHalf, y1, x1, y1);
            ctx.stroke();
        }
    }

    paintConstructorLines(): void {
        let data = this.data;
        let ctorHeight = RacingGraphConstants.ctorHeight;
        this.paintCtx.ctx.globalAlpha = this.ctorLineAlphaTransition.current * 0.4;
        for (let i = 1; i < data.years.length; i++) {
            let prevYear = data.years[i - 1];
            let currYear = data.years[i];
            for (let j = 0; j < prevYear.constructors.length; j++) {
                for (let k = 0; k < currYear.constructors.length; k++) {
                    const prevConstructor = prevYear.constructors[j];
                    const currConstructor = currYear.constructors[k];
                    if (prevConstructor.id === currConstructor.id) {
                        let x0 = prevYear.x + prevYear.width;
                        let x1 = currYear.x;
                        let y0 = prevConstructor.pos.current + ctorHeight / 2;
                        let y1 = currConstructor.pos.current + ctorHeight / 2;
                        let lineRect = Rect.create(x0, y0, x1, y1);
                        let lineWidth = ctorHeight * 1.2;
                        this.paintCurvedLine(lineRect, lineWidth, prevConstructor.color, currConstructor.color);
                    }
                }
            }
        }
    }

    paintDriverLines(): void {
        let data = this.data;
        let driverCtorGroups = this.driverCtorGroups;
        let driverHeight = RacingGraphConstants.driverHeight;
        this.paintCtx.ctx.globalAlpha = this.driverLineAlphaTransition.current * 0.4;
        for (let i = 1; i < data.years.length; i++) {
            let prevYear = data.years[i - 1];
            let currYear = data.years[i];
            let prevDriverCtorGroups = driverCtorGroups.filter(g => g.year.year === prevYear.year);
            let currDriverCtorGroups = driverCtorGroups.filter(g => g.year.year === currYear.year);
            for (let j = 0; j < prevDriverCtorGroups.length; j++) {
                for (let k = 0; k < currDriverCtorGroups.length; k++) {
                    const prevDriverCtorGroup = prevDriverCtorGroups[j];
                    const currDriverCtorGroup = currDriverCtorGroups[k];
                    if (prevDriverCtorGroup.driver.id === currDriverCtorGroup.driver.id) {
                        let x0 = prevYear.x + prevYear.width;
                        let x1 = currYear.x;
                        let y0 = prevDriverCtorGroup.pos.current + driverHeight / 2;
                        let y1 = currDriverCtorGroup.pos.current + driverHeight / 2;
                        let lineRect = Rect.create(x0, y0, x1, y1);
                        let lineWidth = driverHeight * 0.6;
                        this.paintCurvedLine(lineRect, lineWidth, prevDriverCtorGroup.constructor.color, currDriverCtorGroup.constructor.color);
                    }
                }
            }
        }
    }

    paintColumn(title: string, titleHeight: number, x: number, width: number, height: number): void {
        let ctx = this.paintCtx.ctx;
        let visible = this.paintCtx.visible;
        if (x < visible.x1 && x + width > visible.x0 && 0 < visible.y1) {
            // Column Background
            if (height > visible.y0) {
                ctx.fillStyle = "#222";
                ctx.beginPath();
                ctx.fillRect(x, 0, width, height);
            }

            // Title
            if (titleHeight > visible.y0) {
                ctx.fillStyle = "black";
                ctx.beginPath();
                ctx.fillRect(x, 0, width, titleHeight);

                ctx.font = "20px FOneWide";
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "white";
                ctx.fillText(title, x + width / 2, titleHeight / 2);
            }
        }
    }

    paintConstructorName(ctor: F1YearlyConstructor, x: number, y: number): void {
        let ctx = this.paintCtx.ctx;

        ctx.font = "14px FOneRegular";
        ctx.textAlign = "start";
        ctx.textBaseline = "alphabetic";

        let rankText = ctor.rank + " - ";
        ctx.fillStyle = ctor.color;
        ctx.fillText(ctor.rank + " - ", x, y);
        let rankMetrics = ctx.measureText(rankText);

        ctx.fillStyle = "white";
        ctx.fillText(ctor.name, x + rankMetrics.width, y);
        let nameMetrics = ctx.measureText(ctor.name);

        if (ctor.engine) {
            ctx.font = "10px FOneRegular";
            ctx.fillStyle = "white";
            ctx.fillText(" - " + ctor.engine, x + rankMetrics.width + nameMetrics.width, y);
        }
    }
    paintDriverName(driverGroup: DriverCtorGroup, x: number, y: number, width: number): void {
        let ctx = this.paintCtx.ctx;
        let visible = this.paintCtx.visible;
        if (x < visible.x1 && x + width > visible.x0) {
            let rankText = driverGroup.yDriver.rank + " - ";
            ctx.font = "12px FOneRegular";
            ctx.fillStyle = "#888";
            ctx.fillText(rankText, x + 2, y + 10);
            let rankMetrics = ctx.measureText(rankText);

            ctx.font = "10px FOneRegular";
            ctx.fillStyle = "white";
            ctx.fillText(driverGroup.driver.firstNames, x + 2 + rankMetrics.width, y + 10);
            let nameMetrics = ctx.measureText(driverGroup.driver.firstNames);

            ctx.font = "14px FOneRegular";
            ctx.fillText(" " + driverGroup.driver.surname, x + 2 + rankMetrics.width + nameMetrics.width, y + 10);
        }
    }

    paintConstructorPoints(ctor: F1YearlyConstructor, x: number, y: number, width: number): void {
        let ctx = this.paintCtx.ctx;
        let visible = this.paintCtx.visible;
        ctx.textAlign = "right";
        ctx.textBaseline = "middle";
        if (x - width < visible.x1 && x > visible.x0) { // right aligned
            let pointsText = String(ctor.points);
            ctx.font = (ctor.points / 100 + 14) + "px FOneRegular";
            ctx.fillStyle = "black";
            ctx.fillText(pointsText, x + 3, y + 3);
            ctx.fillStyle = "white";
            ctx.fillText(pointsText, x, y);
        }
    }

    paintDriverPoints(driverGroup: DriverCtorGroup, x: number, y: number, width: number): void {
        let ctx = this.paintCtx.ctx;
        let visible = this.paintCtx.visible;
        ctx.textAlign = "right";
        ctx.textBaseline = "middle";
        if (x - width < visible.x1 && x > visible.x0) { // right aligned
            let pointsText = String(driverGroup.yDriver.points);
            ctx.font = (driverGroup.yDriver.points / 80 + 12) + "px FOneRegular";
            ctx.fillStyle = "black";
            ctx.fillText(pointsText, x + 3, y + 3);
            ctx.fillStyle = "#ccc";
            ctx.fillText(pointsText, x, y);
        }
    }

    paintDriverStints(year: F1Year, xOffset: number, yOffset: number): void {
        let ctx = this.paintCtx.ctx;
        let visible = this.paintCtx.visible;
        let raceWidth = RacingGraphConstants.raceWidth;
        let raceHeight = RacingGraphConstants.raceHeight;
        ctx.globalAlpha = this.driverAlphaTransition.current;
        for (let i = 0; i < year.drivers.length; i++) {
            const driver = year.drivers[i];
            for (let j = 0; j < driver.stints.length; j++) {
                const stint = driver.stints[j];

                this.paintCtx.globalTransition.transition(stint.pos);

                let x0 = xOffset + raceWidth * (stint.raceFrom - 1) + raceHeight / 2;
                let x1 = xOffset + raceWidth * (stint.raceTo) - raceHeight / 2;
                let y = stint.pos.current + (yOffset - raceHeight / 2)
                if (x0 < visible.x1 && x1 > visible.x0 && y < visible.y1 && y > visible.y0) {
                    ctx.strokeStyle = stint.constructor.color;
                    ctx.lineWidth = raceHeight;
                    ctx.lineCap = "round";
                    ctx.beginPath();
                    ctx.moveTo(x0, y);
                    ctx.lineTo(x1, y);
                    ctx.stroke();
                }
            }
        }
    }

    paintConstructorBackground(x: number, y: number, width: number, height: number, slashWidth: number, color: string): void {
        let ctx = this.paintCtx.ctx;

        ctx.beginPath();
        ctx.fillStyle = "black";
        ctx.moveTo(x + slashWidth, y + height);
        ctx.lineTo(x + (slashWidth * 2 - 2), y);
        ctx.lineTo(x + width, y);
        ctx.lineTo(x + width - 20, y + height);
        ctx.fill();
        ctx.closePath();

        ctx.beginPath();
        ctx.fillStyle = color;
        ctx.moveTo(x, y + height);
        ctx.lineTo(x + slashWidth, y);
        ctx.lineTo(x + (slashWidth * 2), y);
        ctx.lineTo(x + slashWidth + 4, y + height - 4);
        ctx.lineTo(x + width - 17, y + height - 4);
        ctx.lineTo(x + width - 20, y + height);
        ctx.fill();
        ctx.closePath();
    }

    paintDrivers(year: F1Year, x: number, width: number): void {
        let ctx = this.paintCtx.ctx;
        let visible = this.paintCtx.visible;
        let height = RacingGraphConstants.driverHeight;
        let driverGroups = this.driverCtorGroups.filter(g => g.year.year === year.year);
        ctx.globalAlpha = this.driverAlphaTransition.current;
        for (let i = 0; i < driverGroups.length; i++) {
            const driverGroup = driverGroups[i];

            let pos = driverGroup.pos.current;

            if (ctx.globalAlpha > 0) {
                let imgX = x + 4;
                let imgY = pos + 4;
                let flagUrl = driverGroup.driver.flag;

                this.paintDriverFlag(imgX, imgY, flagUrl);
            }

            let driverX = x + RacingGraphConstants.flagWidth;
            let driverY = pos;
            ctx.textAlign = "start";
            ctx.textBaseline = "alphabetic";
            if (driverY < visible.y1 && driverY + height - 10 > visible.y0) {
                // Driver Names
                this.paintDriverName(driverGroup, driverX, driverY, year.width - RacingGraphConstants.pointsWidth);

                // Driver Points
                let pointsX = driverX + width - RacingGraphConstants.flagWidth - 5;
                let pointsY = driverY + height / 2;
                this.paintDriverPoints(driverGroup, pointsX, pointsY, RacingGraphConstants.pointsWidth);
            }
        }
    }

    paintConstructorNamesAndPoints(year: F1Year, x: number): void {
        let ctx = this.paintCtx.ctx;
        let visible = this.paintCtx.visible;
        let height = RacingGraphConstants.ctorHeight;
        ctx.globalAlpha = this.ctorAlphaTransition.current;
        for (let index = 0; index < year.constructors.length; index++) {
            const ctor = year.constructors[index];

            let pos = ctor.pos.current;

            let ctorX = x;
            let ctorY = pos;
            if (ctorY < visible.y1 && ctorY + height > visible.y0) {

                // Constructor Names
                let ctorWidth = year.width - RacingGraphConstants.pointsWidth;
                if (ctorX < visible.x1 && ctorX + ctorWidth > visible.x0) {
                    this.paintConstructorBackground(ctorX, ctorY, ctorWidth, height, RacingGraphConstants.ctorSlashWidth, ctor.color);
                    this.paintConstructorName(ctor, ctorX + 2 * RacingGraphConstants.ctorSlashWidth + 2, ctorY + 16);
                }

                // Constructor Points
                let pointsX = ctorX + year.width - 5;
                let pointsY = ctorY + height / 2;
                this.paintConstructorPoints(ctor, pointsX, pointsY, RacingGraphConstants.pointsWidth);
            }
        }
    }

    paintDriverFlag(x: number, y: number, flagUrl: string): void {
        let ctx = this.paintCtx.ctx;
        let visible = this.paintCtx.visible;
        let width = RacingGraphConstants.flagWidth;
        let height = RacingGraphConstants.flagHeight;
        let flagGap = RacingGraphConstants.flagGap;
        if (x < visible.x1 && x + width > visible.x0 && y < visible.y1 && y + height > visible.y0) {
            let cachedImg = this.imgCache.find(i => i.srcUrl === flagUrl);
            let imgRequested = true;
            if (!cachedImg) {
                imgRequested = false;
                cachedImg = { srcUrl: flagUrl, imgLoaded: false };
                this.imgCache.push(cachedImg);
            }

            if (cachedImg.img) {
                ctx.drawImage(cachedImg.img, x, y, width - flagGap, height);
            } else if (!imgRequested) {
                let img = new Image();
                img.crossOrigin = "Anonymous";
                img.onload = () => {
                    const newCanvas = document.createElement('canvas');
                    const newCtx = newCanvas.getContext('2d');
                    let dataURL;
                    newCanvas.height = height * 2;
                    newCanvas.width = (width - flagGap) * 2;
                    newCtx?.drawImage(img, 0, 0, newCanvas.width, newCanvas.height);
                    dataURL = newCanvas.toDataURL("png");

                    if (cachedImg) {
                        cachedImg.imgLoaded = true;
                        cachedImg.img = new Image();;
                        cachedImg.img.src = dataURL;
                    }
                    this.paintCtx.repaint();
                }
                img.src = flagUrl;
            }
        }
    }
}