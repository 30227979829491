class ConsentService {

    CONSENT_KEY = 'consent';

    isConsented(): boolean {
        return !!localStorage.getItem(this.CONSENT_KEY);
    }

    setConsented(): void {
        localStorage.setItem(this.CONSENT_KEY, 'y');
    }
}
export const consentService = new ConsentService();